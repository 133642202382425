import React from "react";
import { Link } from "react-router-dom";

export default function EventCard({ imageSrc, _id, fromDate, endDate, price, courseTitle, duration }) {
  return (
    <div className="event-card">
      <div className="blog-card" key={_id}>
        <div className="post-media">
          <Link to="/blog-details">
            <img src={imageSrc} alt="" />
          </Link>
        </div>
        <div className="post-info">
          <ul className="post-meta">
            <li className="author">
              <Link to="/blog-details">
                <i className="fa fa-money-bill"></i>
                {price}
              </Link>
            </li>
            <li className="date">
              <i className="far fa-calendar-alt"></i>
              {duration}
            </li>
          </ul>
          <h5 className="post-title">
            {courseTitle}
          </h5>
          <div className="event-card-dates">
            <div>
              <p>From</p>
              <h5>{fromDate}</h5>
            </div>
            <div>
              <p>To</p>
              <h5>{endDate}</h5>
            </div>
          </div>
          <Link to={`/contact-us`} className="btn btn-outline-primary btn-sm">
            Book
            <i className="btn-icon-bx fas fa-chevron-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}