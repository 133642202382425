import React from "react";

export default function AboutText() {
  return (
    <div className="contact-us-about">
      <h5>Highly reliable professional security solutions</h5>

      <h6>
        Outstanding protection, safety and ergonomic convenience. Highest
        quality international product development standards.
      </h6>
      <p>
        Each of our Instructors/Trainers comes from an Military combat forces
        background, Law Enforcements Counter terrorism units &amp; International
        Security Industry Close Protection operatives. We are a dynamic and
        flexible team. Combining our knowledge and experience, we have dedicated
        ourselves to developing safe, ergonomic products or training which an
        essential for all security &amp; safety tasks. The wide range of our
        security &amp; personal safety courses are of the highest quality and
        meet the demands of all security industry authority standards.  
      </p>

      <p>
         Among our customers are military, paramilitary, law enforcement and
        security organizations across the globe.{" "}
      </p>
      <p>
        While located in North London &amp; Northwest London, we maintain an
        international network of distributors and service representatives to
        quickly and efficiently meet our customers&#39; needs. Dedicated to
        service excellence, we offer highly flexible production periods, 24-hour
        worldwide delivery services and optional on-site training.
      </p>
      <h5>Mission of Confidence</h5>
    </div>
  );
}
