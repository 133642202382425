import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images
import Logo from "../../images/logo.png";
import footerBg from "../../images/background/footer.jpg";
import ptImg1 from "../../images/shap/wave-blue.png";
import ptImg2 from "../../images/shap/circle-dots.png";
import ptImg3 from "../../images/shap/plus-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";

// Social Images
import facebook from "../../images/social/facebook.png";
import twitter from "../../images/social/twitter.png";
import instagram from "../../images/social/instagram.png";
import linkedin from "../../images/social/linkedin.png";
import courses from "../../data/courses";

class aboutSection extends Component {
  render() {
    return (
      <>
        <footer
          className="footer"
          style={{ backgroundImage: "url(" + footerBg + ")" }}
        >
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="widget widget_info">
                    <div className="footer-logo">
                      <Link to="/">
                        <img src={Logo} alt="" />
                      </Link>
                    </div>
                    <div className="ft-contact">
                      <b style={{ fontWeight: 'bold' }}>GOLAN SECURITY GROUP LTD</b>
                      <p>
                        Each of our Instructors/Trainers comes from an Military
                        combat forces background, Law Enforcements Counter
                        terrorism units & International Security Industry Close
                        Protection operatives. We are a dynamic and flexible
                        team. Combining our knowledge and experience, we have
                        dedicated ourselves to developing safe, ergonomic
                        products or training which an essential for all security
                        & safety tasks. The wide range of our security &
                        personal safety courses are of the highest quality and
                        meet the demands of all security industry authority
                        standards.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-6">
                  <div className="widget footer_widget ml-50">
                    <h3 className="footer-title">Quick Links</h3>
                    <ul>
                      <li>
                        <Link to="/about-us">
                          <span>About Us</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/services">
                          <span>Services</span>
                        </Link>
                      </li>
                      <li>
                        <a href="/docs/klav_executive_summary.pdf" download>
                          <span>Krav-Maga Executive Summary</span>
                        </a>
                      </li>
                      <li>
                        <a href="/docs/vip-intro.pdf" download>
                          <span>VIP Protection Introduction</span>
                        </a>
                      </li>
                      {/* <li>
                        <Link to="/booking">
                          <span>Booking</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq">
                          <span>Faq's</span>
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/blog-grid">
                          <span>Blogs</span>
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/team">
                          <span>Our Team</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          <span>Contact Us</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/testimonial">
                          <span>Testimonials</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
          <img className="pt-img2 animate1" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
          <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
        </footer>
      </>
    );
  }
}

export default aboutSection;
