import React, { Component, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Sticky from "react-stickynode";

// Images
import logo from "../../images/logo.png";
import logoWhite from "../../images/logo-white.png";

const Header = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    var searchForm = document.querySelector(".nav-search-bar");
    var closeBtn = document.getElementById("searchRemove");

    closeBtn.addEventListener("click", function () {
      searchForm.classList.remove("show");
    });

    // Mobile Menu Open
    var menuIcon = document.querySelector(".menuicon");
    var menuLinks = document.querySelector(".menu-links");
    var menuClose = document.getElementById("menuClose");

    menuIcon.addEventListener("click", function () {
      menuLinks.classList.add("show");
    });

    menuClose.addEventListener("click", function () {
      menuLinks.classList.remove("show");
    });

    // Mobile Submenu open close function
    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener("click", function () {
        menuClick(this);
      });
    }

    function menuClick(current) {
      const active = current.classList.contains("open");
      navMenu.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
        console.log("active");
      } else {
        current.classList.add("open");
        console.log("close");
      }
    }
  }, []);

  return (
    <>
      <header className="header header-transparent rs-nav">
        <Sticky enabled={true} className="sticky-header navbar-expand-lg">
          <div className="menu-bar clearfix">
            <div className="container-fluid clearfix">
              <div className="menu-logo logo-dark">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <button
                className="navbar-toggler collapsed menuicon justify-content-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#menuDropdown"
                aria-controls="menuDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="secondary-menu">
                <ul>
                  <li className="btn-area">
                    <Link to="/contact-us" className="btn btn-primary shadow">
                      CONTACT US{" "}
                      <i className="btn-icon-bx fas fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="menu-links navbar-collapse collapse justify-content-center"
                id="menuDropdown"
              >
                <div className="menu-logo">
                  <Link to="/">
                    <img src={logoWhite} alt="" />
                  </Link>
                </div>
                <ul className="nav navbar-nav">
                  <li className={location.pathname === "/" && "active"}>
                    <Link to="/">Home</Link>
                  </li>
                  <li className={location.pathname === "/services" && "active"}>
                    <Link to="/services">Courses</Link>
                  </li>
                  <li className={location.pathname === "/events" && "active"}>
                    <Link to="/events">Classes</Link>
                  </li>
                  <li className={location.pathname === "/about-us" && "active"}>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/certificates" && "active"
                    }
                  >
                    <Link to="/certificates">Certificates</Link>
                  </li>
                </ul>
                <ul className="social-media">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-google"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
                <div className="menu-close" id="menuClose">
                  <i className="ti-close"></i>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
        <div className="nav-search-bar">
          <form>
            <input
              type="text"
              className="form-control"
              placeholder="Type to search"
            />
            <span>
              <i className="ti-search"></i>
            </span>
          </form>
          <span id="searchRemove">
            <i className="ti-close"></i>
          </span>
        </div>
      </header>
    </>
  );
};

export default Header;
