export default [
  {
    id: "1",
    title: "Door Supervision & Top-up Training",
    price: "£420",
    duration: "7 Days",
    subtitle:
      "Those who carry out security duties in or at licensed premises (for example pubs and nightclubs), preventing crime and disorder and keeping staff and customers safe.",
    image: "images/courses/door-supervision.jpeg",
    description: [
      `Unit 1: Working in the Private Security Industry

Private Security Industry: The Legal Aspects 
Health and Safety for Private Security Operatives 
Fire Safety Awareness 
Emergency Procedures 
Communication Skills and Customer Care `,
      `Unit 2: Working as a Door Supervisor

Behavioural Standards 
Civil and Criminal Law
Searching
Arrest
Drugs Awareness
Recording Incidents and Crime Scene Preservation
Licensing Law
Emergency Procedures`,
      `Unit 3: Conflict Management for The Private Security Industry

Avoiding Conflict and Reducing Personal Risk 
Defusing Conflicts
Resolving and Learning from Conflicts
Application of Communication Skills and Conflict Management for Door Supervisors `,
      `Unit 4: Physical Intervention Skills for The Private Security Industry

Introduction to Physical Intervention Skills
Need-to-Know Legislation 
`,
    ],
  },
  {
    id: "2",
    title: "Security Officers (guarding & key holding) & Top-up Training",
    price: "£350",
    duration: "5 Days",
    subtitle:
      "Those who guard premises against unauthorised access or occupation, outbreaks of disorder, theft or damage. They may also guard one or more individuals against assault or injuries that occur as the result of the unlawful conduct of others. This protection is given by providing a physical presence or by carrying out a form of patrol or surveillance to deter crime.",

    image: "images/courses/security-officers.jpeg",
    description: [
      `Unit 1: Working in The Private Security Industry`,
      `Unit 2: Working as a Security Officer`,
      `Unit 3: Conflict Management for The Private Security Industry`,
    ],
  },

  {
    id: "4",
    title: "CCTV Operations, Public Space Surveillance & Top-up Training",
    price: "£350",
    duration: "4 Days",
    subtitle:
      "Those who carry out guarding activities using closed circuit television equipment to either monitor the activities of members of the public in a public or private place or to identify a particular person. This includes the use of CCTV to record images to be viewed on non-CCTV equipment.",
    image: "images/courses/cctv.jpeg",
    description: [
      `Unit 1: Working in the Private Security Industry`,
      `Unit 2: Working as a CCTV Operator `,
      `Unit 3: Practical Use of CCTV Systems`,
    ],
  },
  {
    id: "5",
    title: "Close Protection Operatives (VIP Protection) & Top-up Training",
    price: "£2800",
    duration: "23 Days",
    subtitle:
      "Those who guard one or more individuals against assaults or injuries that might be suffered as a consequence of the unlawful conduct of others.",
    image: "images/courses/vip.png",
    description: [
      `Unit 1: Working in the Private Security Industry`,
      `Unit 2: Working as a Close Protection Operative`,
      `Unit 3: Conflict Management`,
      `
    
    Law and Legislation 
Roles and Responsibilities of a CPO
Threat and Risk Assessment 
Incident Management 
Venue Security operations 
Reconnaissance (Theory and Practical)
CPO Protocol and Etiquette 
Operational Planning
Route Selection 
Foot Formations 
Vehicle Tactics
Embus / Debus 
Search Procedures (personal, Vehicle and premises)
Surveillance (Live scenarios in central London)
    `,
    ],
  },
  {
    id: "6",
    title: "Emergency First Aid At Work & Top-up Training",
    price: "£120",
    duration: "2 Days",
    subtitle:
      "Those who guard one or more individuals against assaults or injuries that might be suffered as a consequence of the unlawful conduct of others.",
    image: "images/courses/first-aid-women.jpeg",
    description: [
      `Unit 1: Working in the Private Security Industry`,
      `Unit 2: Working as a Close Protection Operative`,
      `Unit 3: Conflict Management`,
      `
    
    Law and Legislation 
Roles and Responsibilities of a CPO
Threat and Risk Assessment 
Incident Management 
Venue Security operations 
Reconnaissance (Theory and Practical)
CPO Protocol and Etiquette 
Operational Planning
Route Selection 
Foot Formations 
Vehicle Tactics
Embus / Debus 
Search Procedures (personal, Vehicle and premises)
Surveillance (Live scenarios in central London)
    `,
    ],
  },
  {
    id: "8",
    title: "KRAV-MAGA (Self-Defence & Third Party Protection) Training",
    price: "",
    duration: "5 Days",
    subtitle: [
      "- Practitioner Classes £120 per session for up to 10 participants.",
      "- £250 for up to 10 participants in a group session.",
      "- £450 for up to 20 participants in a group session.",
      `- 1.5 hours duration for each session`,
      `- One to one session (Private Session) £120 `,
    ],
    image: "images/courses/krav.jpeg",
    description: [
      `Unit 1: Working in the Private Security Industry`,
      `Unit 2: Working as a Close Protection Operative`,
      `Unit 3: Conflict Management`,
      `
    
    Law and Legislation 
Roles and Responsibilities of a CPO
Threat and Risk Assessment 
Incident Management 
Venue Security operations 
Reconnaissance (Theory and Practical)
CPO Protocol and Etiquette 
Operational Planning
Route Selection 
Foot Formations 
Vehicle Tactics
Embus / Debus 
Search Procedures (personal, Vehicle and premises)
Surveillance (Live scenarios in central London)
    `,
    ],
  },
  {
    id: "9",
    title: "Security Services Consultation",
    price: "",
    duration: "5 Days",
    subtitle: `Education Institutions, Residential Security, Night Clubs, Pubs, Theaters, Concerts, Sport Events, Security Patrols and Close Protection Services`,
    image: "images/courses/cons.jpeg",
    description: [],
  },
];
