import ImageGallery from "react-image-gallery";
const images = [
  {
    original: "images/certificates/10.jpg",
    thumbnail: "images/certificates/10.jpg",
  },
  {
    original: "images/certificates/1.jpg",
    thumbnail: "images/certificates/1.jpg",
  },
  {
    original: "images/certificates/2.jpg",
    thumbnail: "images/certificates/2.jpg",
  },
  {
    original: "images/certificates/3.jpg",
    thumbnail: "images/certificates/3.jpg",
  },
  {
    original: "images/certificates/4.png",
    thumbnail: "images/certificates/4.png",
  },
  {
    original: "images/certificates/5.png",
    thumbnail: "images/certificates/5.png",
  },

  {
    original: "images/certificates/7.png",
    thumbnail: "images/certificates/7.png",
  },
  {
    original: "images/certificates/8.png",
    thumbnail: "images/certificates/8.png",
  },
  {
    original: "images/certificates/9.jpg",
    thumbnail: "images/certificates/9.jpg",
  },

  {
    original: "images/certificates/11.jpg",
    thumbnail: "images/certificates/11.jpg",
  },
  {
    original: "images/certificates/12.jpg",
    thumbnail: "images/certificates/12.jpg",
  },
  {
    original: "images/certificates/13.jpg",
    thumbnail: "images/certificates/13.jpg",
  },
  {
    original: "images/certificates/14.jpg",
    thumbnail: "images/certificates/14.jpg",
  },
];

const Gallery = ({ data }) => {
  return <ImageGallery items={data || images} />;
};

export default Gallery;
