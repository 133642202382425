import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Import Images
import lineCircleBlue from "../../images/shap/line-circle-blue.png";
import squareDotsOrange from "../../images/shap/square-dots-orange.png";
import waveBlue from "../../images/shap/wave-blue.png";
import squareRotate from "../../images/shap/square-rotate.png";
import courses from "../../data/courses";

const ServicesSliderSection = ({ hideTitle = false }) => {
  return (
    <>
      <section className="section-area section-sp1 service-wraper container">
        <div className="row align-items-center">
          {!hideTitle && (
            <div className="col-xl-12 col-lg-12 mb-30">
              <div className="heading-bx text-center">
                <h6 className="title-ext text-secondary">Services</h6>
                <h2 className="title">SIA SECURITY TRAINING COURSES</h2>
              </div>
            </div>
          )}
          <div className="col-xl-12 mb-15">
            <div className="services-content-container">
              {courses.map((item, index) => (
                <div className="blog-card" key={item.id}>
                  <div className="post-media">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="post-info">
                    <ul className="post-meta">
                      <li className="author">
                        <i className="fa fa-money-bill"></i>
                        {item.price}
                      </li>
                      <li className="date">
                        <i className="far fa-calendar-alt"></i>
                        {item.duration}
                      </li>
                    </ul>
                    <h5 className="post-title">{item.title}</h5>
                    {Array.isArray(item.subtitle) ? (
                      item.subtitle.map((i) => <p>{i}</p>)
                    ) : (
                      <p>{item.subtitle}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <img className="pt-img1 animate-rotate" src={lineCircleBlue} alt="" />
        <img className="pt-img2 animate2" src={squareDotsOrange} alt="" />
        <img className="pt-img4 animate1" src={squareRotate} alt="" />
        <img className="pt-img3 animate-wave" src={waveBlue} alt="" />
      </section>
    </>
  );
};

export default ServicesSliderSection;
