import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import Images
import siaApproved from "../../images/sia-approved.JPG";
import highfieldApproved from "../../images/highfield-approved.jpeg";
import kravLogo from "../../images/IKMO-LOGO.png";
import bg1 from "../../images/main-banner/bg1.jpg";
import member1 from "../../images/team/member1.png";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/square-blue.png";
import ptImg3 from "../../images/shap/chicle-blue-2.png";
import ptImg4 from "../../images/shap/plus-orange.png";
import ptImg5 from "../../images/shap/wave-orange.png";

class aboutSection extends Component {
  render() {
    return (
      <>
        <div
          className="main-banner"
          style={{ backgroundImage: "url(" + bg1 + ")" }}
        >
          <div className="container inner-content">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6 col-sm-7">
                <h1 className="text-primary stencil-text">
                  GOLAN SECURITY GROUP LTD
                </h1>
                <h1 className="">SIA SECURITY TRAINING COURSES</h1>
                <div className="d-none d-sm-block">
                  <p className="subtitle">
                    Enhance Your Skills and Forge a Successful Career <br />
                    in Security with our SIA Security Training Courses.
                  </p>
                  <img className="license-photo" src={siaApproved} alt="" />
                  {/* <img
                    className="license-photo large"
                    src={highfieldApproved}
                    alt=""
                  /> */}
                  <img className="license-photo " src={kravLogo} alt="" />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-5">
                <div className="banner-img">
                  <img src={member1} alt="" />
                </div>
                <div className="d-sm-none">
                  <p className="subtitle">
                    Enhance Your Skills and Forge a Successful Career <br />
                    in Security with our SIA Security Training Courses.
                  </p>
                  <img className="license-photo" src={siaApproved} alt="" />
                  {/* <img
                    className="license-photo large"
                    src={highfieldApproved}
                    alt=""
                  /> */}
                  <img className="license-photo " src={kravLogo} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* <img className="pt-img1 animate1" src={ptImg1} alt="" />
          <img className="pt-img2 animate2" src={ptImg2} alt="" />
          <img className="pt-img3 animate3" src={ptImg3} alt="" />
          <img className="pt-img4 animate4" src={ptImg4} alt="" /> */}
          <img className="pt-img5 animate-wave" src={ptImg5} alt="" />
        </div>
      </>
    );
  }
}

export default aboutSection;
