import React, { Component } from "react";

// Import Images
import golan from "../../images/team/golan.jpeg";
import dori from "../../images/team/dori.png";
import brakha from "../../images/team/brakha.jpeg";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/square-dots-orange.png";
import ptImg3 from "../../images/shap/line-circle-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";
import ptImg5 from "../../images/shap/circle-dots.png";

const data = [
  {
    name: "Golan E Levy",
    job: "Director & Head Instructor",
    image: golan,
    resume: "/docs/resume_golan.pdf",
    facebook: "https://twitter.com/",
    linkedin: "https://www.linkedin.com/",
    instagram: "https://www.instagram.com/",
  },
  {
    name: "Master Dori Nemetzky",
    job: "Head of Krav-Maga Training section",
    image: dori,
    resume: "/docs/resume_Dori.pdf",
    facebook: "https://twitter.com/",
    linkedin: "https://www.linkedin.com/",
    instagram: "https://www.instagram.com/",
  },
  {
    name: "Brakha Yafit Howard",
    job: "Administration",
    image: brakha,
    facebook: "https://twitter.com/",
    linkedin: "https://www.linkedin.com/",
    instagram: "https://www.instagram.com/",
  },
];

class TeamSection extends Component {
  render() {
    return (
      <>
        <section className="section-area section-sp3 team-wraper">
          <div className="container">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-secondary">Trainers</h6>
              <h2 className="title">Meet Our Trainers</h2>
            </div>
            <div className="row justify-content-center">
              {data.map((item, index) => (
                <div
                  className="col-lg-4 col-sm-6 mb-30"
                  key={`${item.name}-${index}`}
                >
                  <div className="team-member">
                    <div className="team-media">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="team-info">
                      <div className="team-info-comntent">
                        <h4 className="title">{item.name}</h4>
                        <span className="text-secondary">{item.job}</span>
                      </div>
                      {item.resume && (
                        <a href={item.resume} download>
                          <span>Resume</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img className="pt-img1 animate1" src={ptImg1} alt="" />
          <img className="pt-img2 animate2" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
          <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
          <img className="pt-img5 animate-wave" src={ptImg5} alt="" />
        </section>
      </>
    );
  }
}

export default TeamSection;
